<template>
    <TipoProdutoList />
</template>

<script>
    import TipoProdutoList from '../../components/cadastro/TipoProdutoList.vue';

    export default {
        name: 'Tipo Produto Page',
        components: { TipoProdutoList }
    }

</script>
